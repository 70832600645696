<template>
	<Header />
	<div id="app">
		<div class="layout-container">
			<div class="layout layout--book-details">
				<slot />
			</div>
		</div>
		<Footer no-start />
	</div>
	<ClientOnly>
		<CookieNotification />
	</ClientOnly>
</template>
